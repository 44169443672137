import React from "react"
import Workshop from "../components/workshop/Workshop"
import Layout from '../components/layout'

const miniWorkshop = () =>{
return (
    <Layout>
      <Workshop />
    </Layout>
)
}

export default miniWorkshop
