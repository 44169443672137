import { isEmpty } from "lodash"
import React, { useState } from "react"
import '../../assets/workshop.css'
import Swal from 'sweetalert2'


const Workshop = () =>{

    const initialFormData = Object.freeze({
        FirstName: '',
        LastName: '',
        emailAddress: '',  
        mailedAddress:'',
      })

      const [formData, updateFormData] = useState(initialFormData)

      const handleChange = e => {
        updateFormData({
          ...formData,
    
          // Trimming any whitespace
          [e.target.name]: e.target.value.trim(),
        })
      }

       const data = {
        firstname: formData.FirstName,
        lastname:formData.LastName,
        emailaddress:formData.emailAddress,
        mailedaddress: formData.mailedAddress,
       }

       function workshopMember(){

        typeof window !== 'undefined' ? sessionStorage.clear() : null
        if(isEmpty(formData.FirstName) === false && isEmpty(formData.LastName) === false && isEmpty(formData.emailAddress) === false && isEmpty(formData.mailedAddress) === false){
          sessionStorage.setItem('WorkShop',JSON.stringify(data))
            Swal.fire('Confirm').then(response => {
                /* Read more about isConfirmed, isDenied below */
                if (response.isConfirmed) {
                  sessionStorage.setItem("createWorkshop", true);
                  sessionStorage.setItem("selectedPrice",15);
                  window.location.pathname="/paymentDetails";
                } 
              })
        }
        else{
           alert("Check all fields are filled")
        }
         
       }

       function  workshopNonMember(){
        typeof window !== 'undefined' ? sessionStorage.clear() : null
        if(isEmpty(formData.FirstName) === false && isEmpty(formData.LastName) === false && isEmpty(formData.emailAddress) === false && isEmpty(formData.mailedAddress) === false){
          sessionStorage.setItem('WorkShop',JSON.stringify(data))
            Swal.fire('Confirm').then(response => {
                /* Read more about isConfirmed, isDenied below */
                if (response.isConfirmed) {
                    sessionStorage.setItem("createWorkshop",true);
                    sessionStorage.setItem("selectedPrice",35);
                    window.location.pathname="/paymentDetails";
                } 
              })
        }
        else{
           alert("Check all fields are filled")
        }
       }


    return(
        <div 
        className="card workshopcard"
        style={{
          backgroundColor:"#f9f9ff",
        }}>

        <div className="container pageContainer">
          <h2
            class="membershipTitle"
            style={{
              fontFamily: "Arial",
              fontSize: "28px",
              fontWeight: "600",
              textAlign:"center",
              color: "black",
            }}
          >
             Mini Workshop Registration Form
          </h2>
          <br/>
         
            <label htmlFor="firstname">
              {' '}
              <b>First name</b>
            </label>
            
            <input
              type="text"
              class="form-control"
              placeholder="Enter Your First name"
              name="FirstName"
              required
              onChange={handleChange}
              minlength="2"
              maxlength="20"
            />
            <br />
            <label htmlFor="lastname">
              {' '}
              <b>Last name</b>
            </label>
            
            <input
              type="text"
              class="form-control"
              placeholder="Enter Your Last name"
              name="LastName"
              required
              onChange={handleChange}
              minlength="2"
              maxlength="20"
            />
            <br />
            {/* ********* */}
            
            <label htmlFor="email">
            {' '}
            <b>Email Address</b>
            </label>
            <input
            type="email"
            class="form-control"
            placeholder="Enter your email address"
            name="emailAddress"
            required
            onChange={handleChange}
            minlength="2"
            maxlength="50"
            />
            <br/>
            {/* *********** */}
            <label htmlFor="email">
            {' '}
            <b>Mailed Address</b>
            </label>
            <input
            type="email"
            class="form-control"
            placeholder="Enter your mailed address"
            name="mailedAddress"
            required
            onChange={handleChange}
            minlength="2"
            maxlength="50"
            />
            <br/>
            {/* *********** */}

        <div class="row">
        <div class="column">
        <div >
            <h3  style={{marginBottom:"55px"}}>Members</h3>  
            <div style={{textAlign: "center"}}>
            <h5 style={{backgroundColor:"white", paddingTop:"7px",paddingBottom:"7px", border:"0.1px solid #D9D9D9",borderRadius:"5px",marginBottom:"25px"}}>$15 USD</h5>
            <button type="submit" id="" class="btn btn-success btn5" value="15" onClick={workshopMember}
            style={{backgroundColor:"rgb(0, 128, 0)",
            borderRadius:"7px",
            fontSize:"16px",
            }}>PAY NOW</button>
            </div>
        </div>
        </div>
        
        <div class="column">
        <div >
            <h3  style={{marginBottom:"55px"}}>Non Members</h3>  
            <div style={{textAlign: "center"}}>
            <h5 style={{backgroundColor:"white", paddingTop:"7px",paddingBottom:"7px", border:"0.1px solid #D9D9D9",borderRadius:"5px",marginBottom:"25px"}}>$35 USD</h5>
            <button type="submit" id="" class="btn btn-success btn6" value="35" onClick={workshopNonMember}
            style={{backgroundColor:"rgb(0, 128, 0)",
            borderRadius:"7px",
            fontSize:"16px",
            }}>PAY NOW</button>
            </div>
        </div>
        </div>
    </div>


</div>
        </div>
    )
}

export default Workshop